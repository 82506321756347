<template>
  <vue-easy-lightbox :moveDisabled='true' :visible="visible" :imgs="testImg" :index="index" @hide="showImg(false,'','')">
    <template v-if="testImg.length>1" v-slot:prev-btn="{ prev }">
      <div @click="prev" class="btn__prev">
        <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
          <title>arrowLeft</title>
          <path d="M16.3,6.3L10.8,12l5.5,5.6c0.6,0.6,0.6,1.4,0,2l0,0c-0.6,0.6-1.4,0.6-2,0L7.7,13c-0.6-0.6-0.6-1.4,0-2l6.6-6.6
	c0.6-0.6,1.4-0.6,2,0l0,0C16.9,4.9,16.9,5.8,16.3,6.3z"/>
        </svg>
      </div>
    </template>
    <template v-if="testImg.length>1" v-slot:next-btn="{ next }">
      <div @click="next" class="btn__next">
        <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
          <title>arrowRight</title>
          <path d="M8,17.6l5.5-5.6L8,6.4c-0.6-0.6-0.6-1.4,0-2l0,0c0.6-0.6,1.4-0.6,2,0l6.6,6.6c0.6,0.6,0.6,1.4,0,2L10,19.5
	c-0.6,0.6-1.4,0.6-2,0l0,0C7.4,19,7.4,18.2,8,17.6z"/>
        </svg>
      </div>
    </template>
    <template v-slot:close-btn="{ close }">
      <button @click="close" class="close position-absolute m-0 p-0"></button>
    </template>
  </vue-easy-lightbox>
  
  <div class="scene row justify-content-center">
    
    <!-- 返回頂部 -->
    <div class="goTop">
      <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
    </div> 

    <!-- Filter & intro -->
    <div class="subNav col-xl-10 flex-column flex-sm-row justify-content-between mb-3 mb-md-3 mt-md-1" v-show="!isPagesLoading" ref="clickaway">
      <ul class="subNav__left list-unstyled text-white mb-0 order-12 order-sm-1">
        <li @click="showToggle = !showToggle">
          <button class="btn btn-danger dropdown font-weight-bold text-left text-white" :class="{'dropdown--active':showToggle}">
            <span>{{defaultScene}}</span>
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
            </svg>
          </button>
        </li>

        <transition name="toggle">
          <li v-if="showToggle" class="dropdown__item">
            <ul class="list-unstyled">
              <li v-if="defaultScene!='全部景點'" @click="defaultScene ='全部景點'; ReadData(); showToggle = false">
                <span class="all icon__class mr-1" ></span>
                全部景點
              </li>
              <li v-for="(item, index) in SceneCfg.Classification" :key="index" @click="defaultScene = item; ReadData(index); showToggle = false" >
                <span :class="index.toLowerCase()" class="icon__class mr-1" ></span>
                {{item}}
              </li>
            </ul>
          </li>
        </transition>
      </ul> 
      <router-link to="/intro#part1" class="subNav__right font-weight-bold btn btn-secondary mb-2 mb-sm-0 order-1 order-sm-12" role="button" aria-pressed="true">活動辦法
			</router-link>
    </div>

    <!-- loading -->
    <PagesLoading v-show="isPagesLoading"/>

    <!-- [Left] fixd logo  -->
    <transition name="fade">
      <div v-show="$route.name!='index' && delay" class="page_kv_fixed">
        <img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
      </div>
    </transition>
    
    <!-- [Right] fixd recommend -->
    <transition name="fade">
      <button v-show="delay" @click="showModal = true; Form = true; submitSucceed = false; submitError = false" :class="{ 'active': showModal }" class="scene__recommend position-fixed btn btn-link">
        <img src="@/assets/images/btn_recommend.png" class="w-100" alt="我要推薦新景點">
      </button>
    </transition>
    
    <!-- card -->
    <ul class="col-xl-10 list-unstyled">
      <li class="card mb-4" v-for="(item, index) in SceneList" :key="item.ID" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
        <div v-if="item.VoteSw==1" class="position-absolute vote-show">
          <img src="@/assets/images/img_photography.png" title="攝影邀請賽景點" class="w-100" >      
        </div>
        <div class="row">
          <!-- photo -->
          <div class="col-lg-6 mb-4 mb-lg-0" v-if="UploadStatus(item.Imgs)" @click="ClickNum(index,item.ID)">
            <carousel v-if="item.Imgs.length==1">
              <slide v-for="(photo,index) in UploadStatus(item.Imgs)" :key="index" @click="showImg(true,index,UploadStatus(item.Imgs))">
                <img v-lazy="{src: photo.Img, loading: LoadingImg(photo.Img), error:'http://hilongjw.github.io/vue-lazyload/dist/404.jpg'}">
              </slide>
            </carousel>
            <carousel v-else>
              <slide v-for="(photo,index) in UploadStatus(item.Imgs)" :key="index" @click="showImg(true,index,UploadStatus(item.Imgs))" >
                <img v-lazy="{src: photo.Img, loading: LoadingImg(photo.Img), error:'http://hilongjw.github.io/vue-lazyload/dist/404.jpg'}">
              </slide>
              <template #addons>
                <navigation />
                <pagination class="mb-0 pl-0"/>
              </template>
            </carousel>
          </div>
          <div class="col-lg-6 mb-4 mb-lg-0" v-else>
            <carousel>
              <slide>
                <img src="https://via.placeholder.com/400x300">
              </slide>
            </carousel>
          </div>
          <!-- description -->
          <div class="col-lg-6 d-flex flex-column">
            <div class="des__head">
              <div class="d-flex justify-content-between">
                <h2 class="h4 font-weight-bold mb-1">{{item.Title}}</h2>
                <small>{{index+1}}/{{Scenelength}}</small>
              </div>
              <span class="d-flex align-items-center small mb-3">
                <span :class="item.Classification.toLowerCase()" class="icon__class mr-1"></span>  
                {{SceneCfg.Classification[item.Classification]}}
              </span>
            </div>
            <div class="des__body pb-3">
              <p class="mb-0">{{item.Des}}</p>
              <span class="more position-relative pt-3" :class="{'more--active': item.readMore}">
                <a v-if="!item.readMore" @click="item.readMore = !item.readMore; DesClick(index); ClickNum(index,item.ID)" class="btn w-100">閱讀更多</a>
                <a v-else @click="item.readMore = !item.readMore" class="btn w-100">收合</a>               
              </span>
            </div>
            <div class="des__foot d-flex flex-column flex-md-row align-items-md-end">
              <ul class="flex-grow-1 list-unstyled h6 font-weight-normal mb-0">
                <li v-if="item.Address!=''">地址：{{item.Address}}</li>
                <li v-if="item.TEL!=''">電話：{{item.TEL}}</li>
                <li v-if="item.NickNameByUser!=''">景點推薦人：{{item.NickNameByUser}}</li>
              </ul>
              <!-- <span class="flex-shrink-0 viewer text-right font-weight-bold ml-4 pb-2">
                <ul class="viewer--num list-inline m-0 d-inline">
                  <li class="list-inline-item m-0">
                    <span>{{ ((item.ClickNum=='')?0:parseInt(item.ClickNum)) + ((item.RNum=='')?0:parseInt(item.RNum)) + ((nowClickNum[index]==undefined)?0:parseInt(nowClickNum[index])) }}</span>
                  </li>
                </ul>人<br>
                留下足跡
              </span> -->
            </div>            
          </div>
        </div>
        <!-- 路線圖 tourist guide -->
        <div v-if="item.Line!=''" class="card__guide__list d-flex flex-wrap justify-content-center justify-content-md-start mt-2 mt-lg-3">
          <router-link 
          :to="`/tourist-guide/${item_line}`" v-for="(item_line, index_line) in item.Line.slice(0, -1).split(',')" 
          :key="index_line" class="mr-md-3">
            <!-- {{SceneCfg.Line[item_line]}} -->
            <img class="btn p-0" :src="require('../assets/images/tourist/scene_r'+item_line+'.png')">
          </router-link>
        </div>
      </li>
    </ul>
    

    <!-- recommend modal -->
    <transition name="fade">
      <div v-if="showModal" v-bind:class="{ show: showModal }" class="modal modal__recommend" @click.self="showModal = false">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header position-relative">
              <h5 class="modal-title">我要推薦新景點</h5>
              <button @click="showModal = false" type="button" class="close position-absolute m-0 p-0"></button>
            </div>
            <div class="modal-body row justify-content-center text-secondary">
              <div class="col-lg-9">

                <!-- form -->
                <Form v-if="Form" @submit="onSubmit" method="post">
                  <h5 class="font-weight-bold my-3">推薦景點資訊</h5>
                  <div class="form-group">
                    <label for="ClassByUser">類別 <span class="text-danger">*</span></label>
                    <Field class="form-control mb-1" name="ClassByUser" as="select" rules="required" :value="ClassByUser">
                      <option value="" disabled>請選擇景點類別</option>
                      <option v-for="(item, index) in SceneCfg.Classification" :key="index" :value="index" >{{item}}</option>
                    </Field>
                    <ErrorMessage name="ClassByUser" />
                  </div>
                  <div class="form-group">
                    <label for="TitleByUser">名稱 <span class="text-danger">*</span></label>
                    <Field type="text" class="form-control mb-1" id="TitleByUser" name="TitleByUser" :value="TitleByUser" v-model="TitleByUser" placeholder="請輸入" maxlength="16" rules="required"/>
                    <ErrorMessage name="TitleByUser" />
                  </div>
                  <div class="form-group">
                    <label for="AddressByUser">地址 <span class="text-danger">*</span></label>
                    <Field type="text" class="form-control mb-1" id="AddressByUser" name="AddressByUser" :value="AddressByUser" v-model="AddressByUser" placeholder="請輸入" maxlength="50" rules="required"/>
                    <small>可輸入景點地址、經緯度座標或<a href="https://plus.codes/7QQ35CFJ+QP" target="_blank" class="text-info">Google Plus Code</a>.</small>
                    <ErrorMessage name="AddressByUser" />
                  </div>
                  <div class="form-group">
                    <label for="PhoneLByUser">電話 <span class="text-danger">*</span></label>
                    <div class="form-row">
                      <div class="col-4">
                        <Field type="tel" class="form-control mb-1" id="PhoneLByUser" name="PhoneLByUser" :value="PhoneLByUser" v-model="PhoneLByUser" placeholder="02" maxlength="16" />
                      </div>
                      <div class="col">
                        <Field type="tel" class="form-control mb-1" id="PhoneRByUser" name="PhoneRByUser" :value="PhoneRByUser" v-model="PhoneRByUser" placeholder="請輸入" maxlength="16" rules="required|numeric"/>
                      </div>
                    </div>
                    <ErrorMessage name="PhoneRByUser" />
                  </div>
                  <div class="form-group">
                    <label for="DesByUser">說明 <span class="text-danger">*</span></label>
                    <Field  as="textarea" rows="5" class="form-control mb-1" id="DesByUser" name="DesByUser" :value="DesByUser" v-model="DesByUser" placeholder="請輸入" rules="required"></Field>
                    <ErrorMessage name="DesByUser" />
                  </div>

                  <div class="form-group mb-5">
                    <label for="SwByUser">景點參考圖</label>
                    <div id="upload" class="upload position-relative py-3 px-2 mb-3">
                      <div class="row no-gutters">
                        <div v-for="(item, index) in fileUploadCT" class="col-4 px-2" :key="item.FileRand">
                            <div class="upload__box">
                              <span class="d-inline-block position-absolute badge badge-info" @click="deleteItem(index)">X</span>
                              <img :src="item.Img" alt="" class="">
                            </div>
                        </div>
                        <div class="col-4 px-2" v-if="ImgNum">
                          <div class="upload__box upload__box--default" @click="$refs.file.click()"></div>
                        </div>
                        <input type="file" ref="file" name="fileUpload" id="fileUpload" class="d-none" @change="onFileChange">
                      </div>
                      <span v-show="fileUpLoading" class="upload__loading position-absolute">
                        <UploadLoading class="position-absolute"/>
                      </span>
                    </div>
                    <Field v-if="!uploadRequired" v-slot="{ field }" name="SwByUser" type="radio" :value="SwByUser" rules="required">
                      <div class="custom-control custom-radio mb-1">
                        <input type="radio" id="SwByUser1" name="SwByUser" v-model="SwByUser" value="1" v-bind="field" class="custom-control-input">
                        <label class="custom-control-label" for="SwByUser1">此照片為本人拍攝擁有版權，並願意提供給本活動作為活動推廣使用∘</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="SwByUser2" name="SwByUser" v-model="SwByUser" value="2" v-bind="field" class="custom-control-input">
                        <label class="custom-control-label" for="SwByUser2">此照片非本人拍攝擁有∘</label>
                      </div>
                    </Field>
                    <ErrorMessage name="SwByUser" />
                  </div>

                  <h5 class="font-weight-bold my-3">推薦人資訊</h5>
                  <div class="form-group">
                    <label for="NameByUser">真實姓名 <span class="text-danger">*</span></label>
                    <Field type="text" class="form-control mb-1" id="NameByUser" name="NameByUser"  :value="NameByUser" v-model="NameByUser" placeholder="請輸入" maxlength="16" rules="required"/>
                    <ErrorMessage name="NameByUser" />
                  </div>
                  <div class="form-group">
                    <label for="NickNameByUser">暱稱 <span class="text-danger">*</span></label>
                    <Field type="text" class="form-control mb-1" id="NickNameByUser" name="NickNameByUser" :value="NickNameByUser" v-model="NickNameByUser" placeholder="請輸入" maxlength="16" rules="required"/>
                    <small>此欄將會顯示於網站上</small>
                    <ErrorMessage name="NickNameByUser" />
                  </div>
                  <div class="form-group">
                    <label for="email">Email <span class="text-danger">*</span></label>
                    <Field type="email" class="form-control mb-1" id="EmailByUser" name="EmailByUser" :value="EmailByUser" v-model="EmailByUser" placeholder="請輸入" rules="required|email"/>
                    <ErrorMessage name="EmailByUser" />
                  </div>
                  <Field v-slot="{ field }" name="iagree" type="checkbox" :value="iagree" rules="required">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" id="iagree" name="iagree" v-model="iagree" value="y" v-bind="field" class="custom-control-input">
                      <label class="custom-control-label" for="iagree">我已閱讀並同意<router-link :to="{ name: 'intro', hash: '#sceneIntro_1' }" class="text-info">本活動條款</router-link>、<router-link :to="{ name: 'intro', hash: '#sceneIntro_2' }" class="text-info">注意事項</router-link>、<router-link :to="{ name: 'intro', hash: '#sceneIntro_3' }" class="text-info">個資蒐集聲明條款</router-link>∘
                        </label>
                    </div>
                  </Field>
                  <ErrorMessage name="iagree" />
                  <div class="row justify-content-md-center py-4">
                    <div class="col-6 col-md-4">
                      <button @click="showModal = false" type="button" class="btn btn-lg btn-block btn-infolight text-white">取消</button>
                    </div>
                    <div class="col-6 col-md-4">
                      <button type="submit" class="btn btn-lg btn-block btn-info text-white">確認送出</button>
                    </div>
                  </div>
                </Form>
                <!-- 成功 -->
                <div v-if="submitSucceed" class="text-center py-3 py-md-5">
                  <p class="h2">恭喜您！上傳成功！</p>
                  <p class="h5">我們將有專人進行審核，<br>如通過審核將刊登於活動網站中，<br class="d-block d-md-none">再請密切關注！</p>
                </div>
                <!-- 失敗 -->
                <div v-if="submitError" class="text-center py-3 py-md-5">
                  <p class="h2">上傳失敗了......</p>
                  <p class="h5">請確認您的網路連線後，<br class="d-block d-md-none">再重新操作一次。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showModal" :class="{ show: showModal }" class="modal__backdrop"></div>
    </transition>

  </div>  
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import { onUnmounted, onMounted } from 'vue';
import $ from 'jquery';
import UploadLoading from '@/components/UploadLoading.vue'
import PagesLoading from '@/components/PagesLoading.vue'
import 'vue3-carousel/dist/carousel.css';

defineRule('required', required);
defineRule('email', email);
defineRule('numeric', numeric);
configure({
  generateMessage: localize('zh_TW', {
    messages: {
      required: '此為必填欄位',
      email: '不是正確的Email',
      numeric: '不是正確的電話號碼',
    },
  }),
});

var fileUploadCTIMG=[];
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    UploadLoading,
    PagesLoading,
  },
  data() {
    return {
      delay:false, 
      defaultScene: '全部景點',
      showToggle: false,
      showModal: false,
      isPagesLoading: true,
      SceneList:null,
      Scenelength:null,
      SceneCfg:null,
      viewerNum:0,
      readMore:false,
      Form:false,
      submitSucceed:false,
      submitError:false,
      TmpImgItems: [],
      submitRandom: Math.random(),
      fileUploadCT: [],
      fileUpLoading: false,
      count:1,
      uploadRequired:true,
      ImgNum:true,
      ClassByUser: '',
      TitleByUser: '',
      AddressByUser: '',
      PhoneLByUser: '',
      PhoneRByUser: '',
      NameByUser: '',
      EmailByUser: '',
      SwByUser: false,
      DesByUser: '',
      NickNameByUser:'',
      iagree:false,
      nowClickNum:[],
      visible: false,
      index: 0, // default: 0
      testImg: []
    }
  },
  setup() {
    document.title = "2021淡水八景4.0-全民推薦大搜密";
    document.querySelector('meta[property="og:title"]').setAttribute("content", '2021淡水八景4.0-全民推薦大搜密');
    document.querySelector('meta[name="description"]').setAttribute("content", '喜好淡水的看倌大大們，肯定有不少口袋景點。現在就參加「全民推薦大搜密」活動，讓你的私房好景立馬紅遍全台！');
    document.querySelector('meta[property="og:description"]').setAttribute("content", '喜好淡水的看倌大大們，肯定有不少口袋景點。現在就參加「全民推薦大搜密」活動，讓你的私房好景立馬紅遍全台！');
    

    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        //console.log('yay!')
      }
    };
    // this will register the event when the component is mounted on the DOM
    onMounted(() => {
      //console.log('onmounted')
      window.addEventListener('scroll', handleScroll);
    });
    // We then unregister the listener when the component is removed from the DOM
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    });
  },
  
  /* Init */
  created() {
    this.ReadData();
  },
  mounted() {
    window.addEventListener('click', this.handleClickaway);
  },
	beforeUnmount() {
    window.removeEventListener('click', this.handleClickaway);
  },
  updated(){
    $(window).scroll(function(){
      if($(window).scrollTop()>=1000){
          $(".goTop").fadeIn(300).click(function() {
              $('html, body').stop(true, false).animate({
                  scrollTop: 0
              }, 'slow');
              return false;
          });
      } else{
          $(".goTop").fadeOut(300);
      }
    });
    for(var i=0;i<=$('.des__body').length-1;i++){
      let cardImgH = $( '.carousel:eq('+i+')' ).height();
      let cardHeadH = $( '.des__head:eq('+i+')' ).height();
      let cardBodyH = $( '.des__body p:eq('+i+')' ).height();
      let cardFootH = $( '.des__foot:eq('+i+')' ).height();
      let cardContent = cardImgH - cardHeadH - cardFootH;
      let windowSize = $(window).width();
      //console.log(i,cardBodyH,cardContent,(cardBodyH > cardContent));
      if(cardBodyH+20 > cardContent && windowSize > 991){
        $( '.des__body:eq('+i+')' ).css("max-height",cardContent+'px');
        $( '.des__body .more:eq('+i+')' ).show();
      } else if (cardBodyH+54 > cardImgH && windowSize < 768){
        $( '.des__body:eq('+i+')' ).css("max-height",cardImgH-60+'px');
        $( '.des__body .more:eq('+i+')' ).show();
      }
    }
  },  

  /* Function */
  watch: {
    showModal() {
      this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
    }
  },
  methods: {
    // 載入資料
    ReadData:function(ft=null){//傳入 分類
      var FD = new FormData();
      FD.append('mode', 'sct');
      FD.append('data', '{"class":"Scene","lang":"zh-TW","Imgs":true}');

      this.axios({
          method: 'post',
          url: 'RApi',
          data: FD,
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
          //console.log(response);
          //景點隨機排序，選分類排序固定
          //console.log(this.$route.hash.split("#")[1]);
          //console.log(response.data.data.filter(item => item.Title==this.$route.hash.split("#")[1]));

          if(ft!=null){
            this.SceneList=response.data.data.filter(item => item.Classification==ft&&(item._Status_=='3'||item._Status_=='4')); 
            this.Scenelength=this.SceneList.filter(item => item.Classification==ft&&(item._Status_=='3'||item._Status_=='4')).length;
          }else{
            if(!this.$route.hash){
              var arrs=Array();
              var arr=response.data.data.filter((item) => (item._Status_=='3'||item._Status_=='4'));
              arr.forEach(function(item){
                arrs.push(((!item.TimesNum)?1:item.TimesNum)*10);
              });
              var arrss=arrs.map((v, i) => Array(v).fill(i)).reduce((c, v) => c.concat(v), []).sort(function(){return 0.5 - Math.random()});
              var R=Math.floor((Math.random() * arrss.length));
              var TimesNum=arr[arrss[R]].ID;
              //console.log(arr,arrs,arrss,'[length]'+arrss.length,'[KEY]'+R,'[VAR]'+arrss[R],'[VAR對應ID]'+arr[arrss[R]].ID);
              //console.log(arr[arrss[R]].Title);

              this.SceneList=response.data.data.filter(item => (item._Status_=='3'||item._Status_=='4') && (item.ID!=TimesNum)).sort(function(){return 0.5 - Math.random()});
              this.Scenelength=this.SceneList.filter(item => item._Status_=='3'||item._Status_=='4').length+1;
              this.SceneList.unshift(response.data.data.filter(item => item.ID==TimesNum)[0]);
            }else{
              this.SceneList=response.data.data.filter(item => (item._Status_=='3'||item._Status_=='4') && (item.Title!=this.$route.hash.split("#")[1])).sort(function(){return 0.5 - Math.random()});
              this.Scenelength=this.SceneList.filter(item => item._Status_=='3'||item._Status_=='4').length+1;
              this.SceneList.unshift(response.data.data.filter(item => (item._Status_=='3'||item._Status_=='4') && item.Title==this.$route.hash.split("#")[1])[0]);
            }
          }
          this.SceneCfg=response.data;
          this.isPagesLoading = false;
          this.delay=true;

          setTimeout(function(){
            var current = new Date();    
            var endPart1 = new Date("March 31, 2021 23:59:59") //結束時間
            if(current.getTime()>endPart1.getTime()){
              // console.log(current.getTime()>endPart1.getTime());
              $('.scene__recommend').hide();
            }
          },120);
      })
      .catch(function (error) {
          console.log(error);
      });
    }, 
    UploadStatus: function(response=null){
      if(response!=null){
        return response.filter(item => item.UploadStatus=='3'||item.UploadStatus=='4');
      } else {
        return false;
      }
    },
    LoadingImg: function(Str=null){
      var StrA=Str.split("/");
      var Url='';
      StrA.forEach(function(item,index){if(index<StrA.length-1){Url=Url+item+'/';}});
      return Url+'min/'+StrA[StrA.length-1].split(".")[0]+'.jpeg';
    },

    // 燈箱 lightbox
    showImg:function(Box,index,Item) {
      if(Box!=false){
        this.index = index;
        this.testImg = [];
        //for(let i=0;i<Item.length;i++){this.testImg.push(Item[i].Img);}
        Item.forEach((Items,i) => this.testImg.push(Item[i].Img));
        document.body.classList.add("modal-open");
        this.visible = true;
      }else{
        document.body.classList.remove("modal-open");
        this.visible = false;
      }
    },

    // 閱讀更多
    DesClick:function(e){
      setTimeout(function(){
        $('.des__body:eq('+e+')').css("max-height","500px");
      },120);
    },

    // 計算足跡
    ClickNum:function(index,ID){
      if(this.$cookies.get('ClickNum')==null){this.$cookies.set('ClickNum','0,1');}
      var ClickNumArr=this.$cookies.get('ClickNum').split(",");

      if(ClickNumArr.indexOf(ID.toString())==-1){
        
        this.nowClickNum[index]=1;

        var FD = new FormData();
        FD.append('mode', 'count');
        FD.append('data', '{"id":"'+ID+'","name":"ClickNum"}');
        this.axios({
            method: 'post',
            url: 'RApi',
            data: FD,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        // .then(response => {
        //   console.log(response);
        // })
        ClickNumArr.push(ID.toString());
        this.$cookies.set('ClickNum',ClickNumArr);
      }
    },

    // 上傳圖片(推薦景點)
    onFileChange(e) {//記得add e
      var files = e.target.files || e.dataTransfer.files;
      const FD = new FormData();
      FD.append('File[]', files[0]);
      this.fileUpLoading = true,
      this.axios({
          method: 'post',
          url: 'https://api.2021tamsui.tw/Admin/UP/?Name='+files[0].name+'&mid=0&mclass=BC&lang=BC&class=TEST',
          data: FD,
          headers: {'Content-Type': 'multipart/form-data' }
      }).then(response => {
         //response.data;
        this.fileUpLoading = false,
        this.fileUploadCT.push({Img:response.data,UploadStatus:5,FileRand:Math.random()});
        fileUploadCTIMG.push({Img:response.data,UploadStatus:5,"lang":"zh-TW","UploadImgs": "Imgs","G_P": ""});
        
        if(fileUploadCTIMG.length>=3){
          this.ImgNum=false;
        }
        if(fileUploadCTIMG.length>=1){
          this.uploadRequired=false;
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },    
    deleteItem: function(index){
      this.fileUploadCT.splice(index, 1);
      fileUploadCTIMG.splice(index, 1);
      if(fileUploadCTIMG.length<3){
        this.ImgNum=true;
      }
      if(fileUploadCTIMG.length<1){
          this.uploadRequired=true;
        }
    },

    // 送出表單(推薦景點)
    onSubmit(values) {
      //console.log(JSON.stringify(values, null, 2));

      var FD = new FormData();
      FD.append('mode', 'add');

      var objData ={
          "class": "Scene",
          "lang": "zh-TW",
          "_Status_": 2,
          "Classification": values.ClassByUser,
          "Title": values.TitleByUser,
          "Address": values.AddressByUser,
          "Des": values.DesByUser,
          "ClassByUser": values.ClassByUser,
          "TitleByUser": values.TitleByUser,
          "AddressByUser": values.AddressByUser,
          "PhoneLByUser": values.PhoneLByUser,
          "PhoneRByUser": values.PhoneRByUser,
          "NameByUser": values.NameByUser,
          "EmailByUser": values.EmailByUser,
          "SwByUser": values.SwByUser,
          "DesByUser": values.DesByUser,
          "NickNameByUser":values.NickNameByUser,
          "Imgs":'',
          "UploadImgs":fileUploadCTIMG,
          "RandCode": this.submitRandom,
      };

      FD.append('data',JSON.stringify(objData));
      this.axios({
          method: 'post',
          url: 'RApi',
          data: FD,
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
          //console.log(response);
          this.Form = false;
          if(response.status == 200){
            this.submitSucceed = true;
            this.ClassByUser = '';
            this.TitleByUser = '';
            this.AddressByUser = '';
            this.PhoneLByUser = '';
            this.PhoneRByUser = '';
            this.NameByUser = '';
            this.EmailByUser = '';
            this.SwByUser = false;
            this.DesByUser = '';
            this.NickNameByUser ='';
            this.iagree = false;

            fileUploadCTIMG = [];
            this.fileUploadCT = [];
            this.uploadRequired = true;            
          } else {
            this.submitError = true;
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    },

    // 點擊空白處關閉
    handleClickaway(e) {
			const { clickaway } = this.$refs;
			if(!clickaway.contains(e.target)){
				this.showToggle = false;
			}
    },
  }
}
</script>